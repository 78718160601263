.mapboxgl-ctrl-attrib .mapboxgl-ctrl-attrib-button {
  display: none;
}
.mapboxgl-ctrl-attrib .mapbox-improve-map {
  display: none;
}

div.leaflet-control-attribution.leaflet-control {
  opacity: 0;
}

div.leaflet-control-attribution.leaflet-control.ok {
  opacity: 1;
}

.mapboxgl-ctrl-attrib {
  opacity: 0;
}

.mapboxgl-ctrl-attrib.ok {
  opacity: 1;
}

.mapboxgl-ctrl-attrib-inner {
  color: black;
}

html {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}

body {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}

#root {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}

/* hide the premise logo  */
@media only screen and (max-width: 1500px) {
  .smallhide {
    display: none;
  }
}

.ReactVirtualized__Grid.ReactVirtualized__List {
  outline: 0;
}
